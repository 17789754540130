
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de"
]

export const localeLoaders = {
  "en": [{ key: "../src/lang/en/general.json", load: () => import("../src/lang/en/general.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_general_json" */), cache: true },
{ key: "../src/lang/en/startpage.json", load: () => import("../src/lang/en/startpage.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_startpage_json" */), cache: true },
{ key: "../src/lang/en/boxes.json", load: () => import("../src/lang/en/boxes.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_boxes_json" */), cache: true },
{ key: "../src/lang/en/invoices.json", load: () => import("../src/lang/en/invoices.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_invoices_json" */), cache: true },
{ key: "../src/lang/en/subscriptions.json", load: () => import("../src/lang/en/subscriptions.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_subscriptions_json" */), cache: true },
{ key: "../src/lang/en/payment.json", load: () => import("../src/lang/en/payment.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_payment_json" */), cache: true },
{ key: "../src/lang/en/profile.json", load: () => import("../src/lang/en/profile.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_profile_json" */), cache: true },
{ key: "../src/lang/en/security.json", load: () => import("../src/lang/en/security.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_en_security_json" */), cache: true }],
  "de": [{ key: "../src/lang/de/general.json", load: () => import("../src/lang/de/general.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_general_json" */), cache: true },
{ key: "../src/lang/de/startpage.json", load: () => import("../src/lang/de/startpage.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_startpage_json" */), cache: true },
{ key: "../src/lang/de/boxes.json", load: () => import("../src/lang/de/boxes.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_boxes_json" */), cache: true },
{ key: "../src/lang/de/invoices.json", load: () => import("../src/lang/de/invoices.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_invoices_json" */), cache: true },
{ key: "../src/lang/de/subscriptions.json", load: () => import("../src/lang/de/subscriptions.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_subscriptions_json" */), cache: true },
{ key: "../src/lang/de/payment.json", load: () => import("../src/lang/de/payment.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_payment_json" */), cache: true },
{ key: "../src/lang/de/profile.json", load: () => import("../src/lang/de/profile.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_profile_json" */), cache: true },
{ key: "../src/lang/de/security.json", load: () => import("../src/lang/de/security.json" /* webpackChunkName: "locale__builds_raidboxes_fred_frontend_src_lang_de_security_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../vue-i18n.config.ts?hash=ebc67d6d&config=1" /* webpackChunkName: "__vue_i18n_config_ts_ebc67d6d" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "src",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./vue-i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "files": [
        "/builds/raidboxes/fred/frontend/src/lang/en/general.json",
        "/builds/raidboxes/fred/frontend/src/lang/en/startpage.json",
        "/builds/raidboxes/fred/frontend/src/lang/en/boxes.json",
        "/builds/raidboxes/fred/frontend/src/lang/en/invoices.json",
        "/builds/raidboxes/fred/frontend/src/lang/en/subscriptions.json",
        "/builds/raidboxes/fred/frontend/src/lang/en/payment.json",
        "/builds/raidboxes/fred/frontend/src/lang/en/profile.json",
        "/builds/raidboxes/fred/frontend/src/lang/en/security.json"
      ],
      "name": "English"
    },
    {
      "code": "de",
      "files": [
        "/builds/raidboxes/fred/frontend/src/lang/de/general.json",
        "/builds/raidboxes/fred/frontend/src/lang/de/startpage.json",
        "/builds/raidboxes/fred/frontend/src/lang/de/boxes.json",
        "/builds/raidboxes/fred/frontend/src/lang/de/invoices.json",
        "/builds/raidboxes/fred/frontend/src/lang/de/subscriptions.json",
        "/builds/raidboxes/fred/frontend/src/lang/de/payment.json",
        "/builds/raidboxes/fred/frontend/src/lang/de/profile.json",
        "/builds/raidboxes/fred/frontend/src/lang/de/security.json"
      ],
      "name": "Deutsch"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "legacy": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/general.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/startpage.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/boxes.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/invoices.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/subscriptions.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/payment.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/profile.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/en/security.json"
      }
    ],
    "name": "English"
  },
  {
    "code": "de",
    "files": [
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/general.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/startpage.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/boxes.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/invoices.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/subscriptions.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/payment.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/profile.json"
      },
      {
        "path": "/builds/raidboxes/fred/frontend/src/lang/de/security.json"
      }
    ],
    "name": "Deutsch"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
