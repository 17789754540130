import { defineNuxtPlugin } from '#app/nuxt'
import { LazyUserAccountCancellationActiveSubscriptions, LazyUserAccountCancellationDemoboxes, LazyUserAccountCancellationFeedback, LazyUserAccountCancellationFinished, LazyUserAccountCancellationReasons, LazyUserAccountCancellationUnpaidInvoices, LazyUserChangeEmailEnterCode, LazyUserChangeEmailEnterNew, LazyUserChangeEmailNewConfirmed, LazyBoxesBoxCard, LazyBoxesBoxFilterDropdown, LazyBoxesBoxStatusIcon, LazyBoxesList, LazyBoxesTable } from '#components'
const lazyGlobalComponents = [
  ["UserAccountCancellationActiveSubscriptions", LazyUserAccountCancellationActiveSubscriptions],
["UserAccountCancellationDemoboxes", LazyUserAccountCancellationDemoboxes],
["UserAccountCancellationFeedback", LazyUserAccountCancellationFeedback],
["UserAccountCancellationFinished", LazyUserAccountCancellationFinished],
["UserAccountCancellationReasons", LazyUserAccountCancellationReasons],
["UserAccountCancellationUnpaidInvoices", LazyUserAccountCancellationUnpaidInvoices],
["UserChangeEmailEnterCode", LazyUserChangeEmailEnterCode],
["UserChangeEmailEnterNew", LazyUserChangeEmailEnterNew],
["UserChangeEmailNewConfirmed", LazyUserChangeEmailNewConfirmed],
["BoxesBoxCard", LazyBoxesBoxCard],
["BoxesBoxFilterDropdown", LazyBoxesBoxFilterDropdown],
["BoxesBoxStatusIcon", LazyBoxesBoxStatusIcon],
["BoxesList", LazyBoxesList],
["BoxesTable", LazyBoxesTable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
