export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const chargebeeJs = useScript(
    { src: 'https://js.chargebee.com/v2/chargebee.js' },
    { use: () => window.Chargebee },
  );
  chargebeeJs.onLoaded(() => {
    window.Chargebee.init({
      site: config.public.chargebee.site,
      publishableKey: config.public.chargebee.publishableKey,
    });
  });
});
