<template>
  <div>
    <div class="flex justify-between">
      <div class="flex space-x-2">
        <base-user-profile-picture size="md" />
        <div>
          <rb-link
            v-if="!hasName"
            size="sm"
            type="neutral"
            trailing-icon-name="plus"
            href="/user/profile"
            @click.prevent="navigateTo('/user/profile')"
          >
            {{ $t('sidebar.user_area.add_name') }}
          </rb-link>
          <div
            v-else
            class="max-w-[10rem] truncate text-md font-semibold text-neutral-700"
            :title="name"
          >
            {{ name }}
          </div>
          <div class="max-w-[10rem] truncate font-text text-xs text-neutral-600" :title="email">
            {{ email }}
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <div v-if="open" class="mt-1 space-y-2">
              <div
                v-if="username"
                class="flex items-center gap-x-1 font-text text-xs text-neutral-600"
              >
                <rb-tooltip :title="$t('sidebar.user_area.username.tooltip')">
                  {{ username }}
                </rb-tooltip>
                <rb-tooltip :title="$t('sidebar.user_area.username.copy.action')">
                  <rb-button
                    hierarchy="plain"
                    type="neutral"
                    icon="only"
                    icon-name="copy"
                    size="sm"
                    :alt-text="$t('sidebar.user_area.username.copy.action')"
                    @click="() => copyToClipboard(username, 'username')"
                  />
                </rb-tooltip>
              </div>
              <div
                v-if="userId"
                class="flex items-center gap-x-1 font-text text-xs text-neutral-600"
              >
                <rb-tooltip :title="$t('sidebar.user_area.user_id.tooltip')">
                  {{ userId }}
                </rb-tooltip>
                <rb-tooltip :title="$t('sidebar.user_area.user_id.copy.action')">
                  <rb-button
                    hierarchy="plain"
                    type="neutral"
                    icon="only"
                    icon-name="copy"
                    size="sm"
                    :alt-text="$t('sidebar.user_area.user_id.copy.action')"
                    @click="() => copyToClipboard(userId, 'user_id')"
                  />
                </rb-tooltip>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <rb-button
        :alt-text="$t('sidebar.accordion_button.alt_text')"
        icon="only"
        :icon-name="open ? 'chevron-up' : 'chevron-down'"
        hierarchy="tertiary"
        size="sm"
        type="neutral"
        class="mt-1"
        @click="open = !open"
      />
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <ul v-if="open">
        <rb-divider class="my-2" />

        <base-layout-navigation-button
          name="User profile"
          size="sm"
          icon="user"
          to="/user/profile"
        />

        <base-layout-navigation-button
          name="Security"
          size="sm"
          icon="settings"
          to="/user/security"
        />

        <rb-divider class="my-2" />

        <rb-navigation-button
          icon-name="log-out"
          size="sm"
          type="error"
          :icon-alt-text="$t('header.logout')"
          @click="() => authStore.logout()"
        >
          {{ $t('header.logout') }}
        </rb-navigation-button>
      </ul>
    </transition>
  </div>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
const { copy } = useClipboard();

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  email: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  userId: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  username: {
    type: String as PropType<string | null>,
    required: false,
    default: '',
  },
});

const open = ref<boolean>(false);

const route = useRoute();

watch(
  () => route.path,
  () => {
    if (open.value && !route.path.startsWith('/user')) {
      open.value = false;
    }
    if (!open.value && route.path.startsWith('/user')) {
      open.value = true;
    }
  },
);

const hasName = computed<boolean>(() => props.name.trim() !== '');

const copyToClipboard = (textToCopy: string, type: string) => {
  copy(textToCopy);
  useNotify(
    'info',
    `sidebar.user_area.${type}.copied.title`,
    'sidebar.user_area.copied.description',
    {
      iconName: 'copy',
      descriptionData: {
        copiedText: textToCopy,
      },
    },
  );
};
</script>
