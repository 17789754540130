import { useScriptGoogleTagManager, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleTagManager = useScriptGoogleTagManager({"id":"GTM-K8QZ3RZ"})
    const bugHerd = useScript({"src":"https://www.bugherd.com/sidebarv2.js?apikey=bjojh9kabsze6xnaafdelg","key":"bugHerd"}, { use: () => ({ bugHerd: window.bugHerd }) })
    const newRelic = useScript({"src":"/newrelic.js","key":"newRelic"}, { use: () => ({ newRelic: window.newRelic }) })
    return { provide: { $scripts: { bugHerd, newRelic, googleTagManager } } }
  }
})