import defu from 'defu';
import { type FetchOptions } from 'ofetch';
import destr from 'destr';
import type { Dayjs } from 'dayjs';
import { useAuthStore } from '~/stores/auth';
import type { NestedObject } from '~/composables/useChangeCase';
import type { UseFetchOptions } from '#app';

async function checkAuthorization(checkToken = true) {
  const authStore = useAuthStore();

  if (authStore.isLoggedIn && checkToken) {
    const dayjs = useDayjs();
    const expireDate = authStore.getTokenExpireDate as Dayjs;
    const currentDate = dayjs();

    const minutes = expireDate.diff(currentDate, 'minute');
    if (minutes < 5) {
      await authStore.refreshToken();
    }
  }
}

export async function useCustomFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
  checkToken = true,
) {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  await checkAuthorization(checkToken);

  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.gatewayBaseUrl,
    headers: authStore.isLoggedIn
      ? { Accept: 'application/json', Authorization: `Bearer ${authStore.getToken}` }
      : {},
    transform: (data) => useChangeKeys(data as NestedObject) as T,
  };

  const params = defu(defaults, options);

  return useFetch(url, params);
}

export async function $api<T>(url: string, options: FetchOptions = {}, checkToken = true) {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  await checkAuthorization(checkToken);

  const defaults: FetchOptions = {
    baseURL: config.public.gatewayBaseUrl,
    headers: authStore.isLoggedIn
      ? { Accept: 'application/json', Authorization: `Bearer ${authStore.getToken}` }
      : {},
    parseResponse: (responseText) => useChangeKeys(destr(responseText)),
    async onResponseError({ response }) {
      if (response.status === 401) {
        const nuxtConfig = useRuntimeConfig();
        await navigateTo(nuxtConfig.public.loginPageUrl, { external: true });
      }
    },
  };

  const params = defu(defaults, options);

  const customFetch = $fetch.create<T>(params);

  return customFetch<T>(url);
}
