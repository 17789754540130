import revive_payload_client_4sVQNw7RlN from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/raidboxes/fred/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/builds/raidboxes/fred/frontend/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_YdLad5Mpq3 from "/builds/raidboxes/fred/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/raidboxes/fred/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jozdw60ZsE from "/builds/raidboxes/fred/frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/raidboxes/fred/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/raidboxes/fred/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/builds/raidboxes/fred/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import array_lEExQTLi8p from "/builds/raidboxes/fred/frontend/src/plugins/array.ts";
import chargebee_zj8dbGCiEv from "/builds/raidboxes/fred/frontend/src/plugins/chargebee.ts";
import clickOutside_0ynIqyw7XJ from "/builds/raidboxes/fred/frontend/src/plugins/clickOutside.ts";
import designsystem_ZZw60nng1n from "/builds/raidboxes/fred/frontend/src/plugins/designsystem.ts";
import featureDirective_auS6Cf5JnP from "/builds/raidboxes/fred/frontend/src/plugins/featureDirective.ts";
import i18n_YCWKwfVXF6 from "/builds/raidboxes/fred/frontend/src/plugins/i18n.ts";
import sentry_client_KAXFuL2wum from "/builds/raidboxes/fred/frontend/src/plugins/sentry.client.ts";
import validation_HY2e0OvVTz from "/builds/raidboxes/fred/frontend/src/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_Jozdw60ZsE,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AUP22rrBAc,
  array_lEExQTLi8p,
  chargebee_zj8dbGCiEv,
  clickOutside_0ynIqyw7XJ,
  designsystem_ZZw60nng1n,
  featureDirective_auS6Cf5JnP,
  i18n_YCWKwfVXF6,
  sentry_client_KAXFuL2wum,
  validation_HY2e0OvVTz
]