
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as backupsILOwit9KAFMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/backups.vue?macro=true";
import { default as configurationyQO9DOe2hrMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/configuration.vue?macro=true";
import { default as monitoring1Cm90p83HtMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/monitoring.vue?macro=true";
import { default as overviewPilq7LXSyQMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/overview.vue?macro=true";
import { default as plugins_45themes16bcZzssnpMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/plugins-themes.vue?macro=true";
import { default as settingsOimglYdG7kMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/settings.vue?macro=true";
import { default as _91id_93KsVJWxUdQ9Meta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/[id].vue?macro=true";
import { default as generalnCnVgqmuuVMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/general.vue?macro=true";
import { default as select_45planNzTAP0Q24cMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/select-plan.vue?macro=true";
import { default as summaryKJ6j5DOV2VMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/summary.vue?macro=true";
import { default as wordpressrHW587g6WJMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/wordpress.vue?macro=true";
import { default as create_45newOx24YY3bNFMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/create-new.vue?macro=true";
import { default as indexVDF68qf8KOMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/index.vue?macro=true";
import { default as add_45onsjxYKx91OqdMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/add-ons.vue?macro=true";
import { default as payment_45options3sr9uMOPpjMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/payment-options.vue?macro=true";
import { default as select_45planhdpCwdDlBuMeta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/select-plan.vue?macro=true";
import { default as summaryyABcVdwgY8Meta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/summary.vue?macro=true";
import { default as _91id_93vhtf7ijt46Meta } from "/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id].vue?macro=true";
import { default as indexl0RccyRqBdMeta } from "/builds/raidboxes/fred/frontend/src/pages/index.vue?macro=true";
import { default as invoicesil6PbPci3KMeta } from "/builds/raidboxes/fred/frontend/src/pages/invoices.vue?macro=true";
import { default as payment_45methodseGDtMNlEowMeta } from "/builds/raidboxes/fred/frontend/src/pages/payment-methods.vue?macro=true";
import { default as _91id_93TnN5DwVuZQMeta } from "/builds/raidboxes/fred/frontend/src/pages/subscriptions/[id].vue?macro=true";
import { default as index0sJjWLLBnnMeta } from "/builds/raidboxes/fred/frontend/src/pages/subscriptions/index.vue?macro=true";
import { default as subscriptionsyIGUoMq9zBMeta } from "/builds/raidboxes/fred/frontend/src/pages/subscriptions.vue?macro=true";
import { default as profileavaK5UJ6rSMeta } from "/builds/raidboxes/fred/frontend/src/pages/user/profile.vue?macro=true";
import { default as security5OmjKLXpFBMeta } from "/builds/raidboxes/fred/frontend/src/pages/user/security.vue?macro=true";
import { default as _91code_9382QtVdSBa0Meta } from "/builds/raidboxes/fred/frontend/src/pages/verification/[id]/email/[code].vue?macro=true";
export default [
  {
    name: "boxes-id",
    path: "/boxes/:id()",
    meta: _91id_93KsVJWxUdQ9Meta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/[id].vue"),
    children: [
  {
    name: "boxes-id-backups",
    path: "backups",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/backups.vue")
  },
  {
    name: "boxes-id-configuration",
    path: "configuration",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/configuration.vue")
  },
  {
    name: "boxes-id-monitoring",
    path: "monitoring",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/monitoring.vue")
  },
  {
    name: "boxes-id-overview",
    path: "overview",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/overview.vue")
  },
  {
    name: "boxes-id-plugins-themes",
    path: "plugins-themes",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/plugins-themes.vue")
  },
  {
    name: "boxes-id-settings",
    path: "settings",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/[id]/settings.vue")
  }
]
  },
  {
    name: "boxes-create-new",
    path: "/boxes/create-new",
    meta: create_45newOx24YY3bNFMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/create-new.vue"),
    children: [
  {
    name: "boxes-create-new-general",
    path: "general",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/general.vue")
  },
  {
    name: "boxes-create-new-select-plan",
    path: "select-plan",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/select-plan.vue")
  },
  {
    name: "boxes-create-new-summary",
    path: "summary",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/summary.vue")
  },
  {
    name: "boxes-create-new-wordpress",
    path: "wordpress",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/create-new/wordpress.vue")
  }
]
  },
  {
    name: "boxes",
    path: "/boxes",
    meta: indexVDF68qf8KOMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/index.vue")
  },
  {
    name: "boxes-upgrade-id",
    path: "/boxes/upgrade/:id()",
    meta: _91id_93vhtf7ijt46Meta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id].vue"),
    children: [
  {
    name: "boxes-upgrade-id-add-ons",
    path: "add-ons",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/add-ons.vue")
  },
  {
    name: "boxes-upgrade-id-payment-options",
    path: "payment-options",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/payment-options.vue")
  },
  {
    name: "boxes-upgrade-id-select-plan",
    path: "select-plan",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/select-plan.vue")
  },
  {
    name: "boxes-upgrade-id-summary",
    path: "summary",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/boxes/upgrade/[id]/summary.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexl0RccyRqBdMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: invoicesil6PbPci3KMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/invoices.vue")
  },
  {
    name: "payment-methods",
    path: "/payment-methods",
    meta: payment_45methodseGDtMNlEowMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/payment-methods.vue")
  },
  {
    name: subscriptionsyIGUoMq9zBMeta?.name,
    path: "/subscriptions",
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/subscriptions.vue"),
    children: [
  {
    name: "subscriptions-id",
    path: ":id()",
    meta: _91id_93TnN5DwVuZQMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/subscriptions/[id].vue")
  },
  {
    name: "subscriptions",
    path: "",
    meta: index0sJjWLLBnnMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/subscriptions/index.vue")
  }
]
  },
  {
    name: "user-profile",
    path: "/user/profile",
    meta: profileavaK5UJ6rSMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/user/profile.vue")
  },
  {
    name: "user-security",
    path: "/user/security",
    meta: security5OmjKLXpFBMeta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/user/security.vue")
  },
  {
    name: "verification-id-email-code",
    path: "/verification/:id()/email/:code()",
    meta: _91code_9382QtVdSBa0Meta || {},
    component: () => import("/builds/raidboxes/fred/frontend/src/pages/verification/[id]/email/[code].vue")
  }
]