<template>
  <base-gravatar-wrapper #default="slotProps" :gravatar-url="gravatarUrl">
    <rb-avatar-label-group
      v-if="isLabelGroup"
      class="mt-3"
      :size="size"
      state="default"
      :avatar-src="slotProps.hasGravatar ? gravatarUrl : ''"
      :avatar-initials="initials"
      :alt-text="
        $t('header.profile.alt_text', { name: includeYouString ? getNameWithYouString : name })
      "
      :title="includeYouString ? getNameWithYouString : name"
      :supporting-text="supportingText"
    />
    <rb-avatar
      v-else
      :size="size"
      state="default"
      :avatar-src="slotProps.hasGravatar ? gravatarUrl : ''"
      :initials="initials"
      :alt-text="$t('header.profile.alt_text', { name: name })"
      :has-info-badge="hasInfoBadge"
      :info-badge-title="infoBadgeTitle"
      :info-badge-supporting-text="infoBadgeSupportingText"
    />
  </base-gravatar-wrapper>
</template>

<script setup lang="ts">
const { t } = useI18n();

const userStore = useUserStore();
const { name, gravatarUrl, initials } = storeToRefs(userStore);

defineProps({
  size: {
    type: String as PropType<string>,
    default: 'sm',
  },
  isLabelGroup: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  supportingText: {
    type: String as PropType<string>,
    default: '',
  },
  includeYouString: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  hasInfoBadge: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  infoBadgeTitle: {
    type: String as PropType<string>,
    default: '',
  },
  infoBadgeSupportingText: {
    type: String as PropType<string>,
    default: '',
  },
});

const getNameWithYouString = computed<string>(() => {
  if (name === undefined || name.value.length <= 2) {
    return t('boxes.create_new.general.box_owner.you');
  }
  return `${name.value} (${t('boxes.create_new.general.box_owner.you')})`;
});
</script>
