import type { LocationQuery } from 'vue-router';
import jwtDecode from 'jwt-decode';
import type { Auth, JwtPayloadRb, RefreshTokenResponse } from '~/interfaces/Auth';

export const useAuthStore = defineStore('authStore', {
  state: (): Auth => ({
    user: {
      id: '',
      trashboardId: '',
      organization: '',
      roles: [],
    },
    token: null,
    expireDate: null,
    interval: null,
    refreshingToken: false,
  }),
  actions: {
    async checkForToken(routeQuery: LocationQuery) {
      const { token } = routeQuery;
      if (token !== undefined && token !== null) {
        this.token = token.toString();
        await this.parseToken();
      }
    },
    async parseToken() {
      if (this.token != null) {
        try {
          const dayjs = useDayjs();
          const decoded = jwtDecode<JwtPayloadRb>(this.token);
          this.expireDate = dayjs.unix(decoded.exp as number);
          this.user = {
            id: decoded.sub,
            trashboardId: decoded.trashboard_id,
            organization: decoded.organization,
            roles: decoded.roles,
          };
          this.startInterval();
        } catch (e) {
          await this.logout();
        }
      } else {
        await this.logout();
      }
    },
    async refreshToken() {
      if (!this.refreshingToken) {
        this.refreshingToken = true;
        await $api<RefreshTokenResponse>('/api/v1/refresh-token', {}, false)
          .then((data) => {
            this.token = data.accessToken;
            this.parseToken();
            this.$persist();
            return data;
          })
          .catch(() => {
            this.logout();
          })
          .finally(() => {
            this.refreshingToken = false;
          });
      }
    },
    startInterval() {
      if (!this.interval) {
        // check every minute
        this.interval = setInterval(() => this.checkTokenExpireDate(), 60000);
      }
    },
    async checkTokenExpireDate() {
      const dayjs = useDayjs();
      const minutesTillExpiration = this.expireDate?.diff(dayjs(), 'minute') as number;
      if (minutesTillExpiration < 5) {
        await this.refreshToken();
      }
    },
    async logout() {
      const config = useRuntimeConfig();
      this.user = {
        id: '',
        trashboardId: '',
        organization: '',
        roles: [],
      };
      this.token = null;
      this.$persist();
      await navigateTo(config.public.loginPageUrl, { external: true });
    },
  },
  getters: {
    isLoggedIn: (state) => state.token !== null && state.token.length > 0,
    getToken: (state) => state.token,
    getTokenExpireDate: (state) => state.expireDate,
    getUser: (state) => state.user,
    getUserId: (state) => state.user?.id,
    getUserTrashboardId: (state) => state.user?.trashboardId,
    getUserOrganizationId: (state) => state.user?.organization,
    getRoles: (state): UserRole[] => {
      const dayjs = useDayjs();
      if (state.user) {
        return state.user.roles.filter(
          (role) => role.expires_at === null || dayjs().isSameOrBefore(role.expires_at),
        );
      }
      return [];
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
    paths: ['token'],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
