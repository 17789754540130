import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  const config = useRuntimeConfig();
  await authStore.checkForToken(to.query);

  if (!authStore.isLoggedIn) {
    await navigateTo(config.public.loginPageUrl, { external: true });
  } else if (authStore.getUserId === '') {
    await authStore.parseToken();
  }
});
