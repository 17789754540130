<template>
  <rb-sidebar
    :on-close-sidebar="() => layoutStore.toggleSidebar()"
    :close-button-alt-text="$t('general.button.close')"
    :on-logo-click="() => navigateTo('/')"
  >
    <rb-button
      v-feature="'BoxPages'"
      class="mb-6 inline-flex lg:hidden"
      hierarchy="secondary"
      size="sm"
      @click="
        () => {
          layoutStore.closeSidebar();
          navigateTo('/boxes/create-new');
        }
      "
    >
      {{ $t('header.button.create_new_box') }}
    </rb-button>
    <rb-sidebar-section :name="$t('sidebar.category.general')">
      <base-layout-navigation-button name="Home" icon="home" />
      <div v-feature="'BoxPages'">
        <base-layout-navigation-button
          name="Dashboard"
          icon="bar-chart"
          to="/dashboard"
          has-sub-menu
        >
          <template #sub-menu>
            <base-layout-navigation-button name="Boxes" to="/boxes" />
          </template>
        </base-layout-navigation-button>
      </div>
    </rb-sidebar-section>
    <rb-sidebar-section :name="$t('sidebar.category.orders')">
      <base-layout-navigation-button name="Invoices" icon="clock-fast-forward" to="/invoices" />
      <base-layout-navigation-button name="Subscriptions" icon="layers" to="/subscriptions" />
      <div v-feature="'DataSync'">
        <base-layout-navigation-button
          name="Payment methods"
          icon="pie-chart"
          to="/payment-methods"
        />
      </div>
    </rb-sidebar-section>

    <template #menu-items-bottom>
      <rb-sidebar-section>
        <div id="beamer-trigger">
          <rb-navigation-button
            icon-name="announcement"
            :icon-alt-text="$t('sidebar.menu_item.nems.icon_alt_text')"
          >
            {{ $t('sidebar.menu_item.news') }}
          </rb-navigation-button>
        </div>
      </rb-sidebar-section>
    </template>
    <template #account-info>
      <div class="mb-4 text-xs font-semibold leading-6 text-gray-600">Account</div>
      <base-layout-account-accordion
        :name="name"
        :email="profile.email"
        :user-id="profile.trashboardId"
        :username="rbUsername"
      />
    </template>
    <template #title="{ titleClasses }">
      <rb-link
        v-if="name.trim() === ''"
        size="sm"
        type="neutral"
        trailing-icon-name="plus"
        href="/user/profile"
        @click.prevent="navigateTo('/user/profile')"
      >
        {{ $t('sidebar.user_area.add_name') }}
      </rb-link>
      <p :class="titleClasses">{{ name }}</p>
    </template>
  </rb-sidebar>
</template>

<script setup lang="ts">
const layoutStore = useLayoutStore();

const userStore = useUserStore();
const { name, profile } = storeToRefs(userStore);

const rbUsername = computed<string | null>(() => {
  const username = profile.value.attributes.find((item) => item.name === 'external_identifier');
  if (username && username !== undefined) {
    return username?.value;
  }
  return null;
});
</script>

<script lang="ts">
export default {
  name: 'BaseSidebar',
};
</script>
<style lang="css">
#lang-switch-sidebar > div > button {
  width: 100%;
}

#beamer-trigger .beamer_icon.active,
#beamer-trigger #beamerIcon.active {
  background-color: theme('colors.info.50') !important;
  color: theme('colors.info.700');
  height: 22px;
  width: 22px;
  line-height: 22px;
  font-size: theme('fontSize.xs');
  right: 12px;
  transform: translateY(50%);
}
</style>
