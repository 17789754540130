import validate from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/builds/raidboxes/fred/frontend/src/middleware/01.auth.global.ts";
import _02_45feature_45global from "/builds/raidboxes/fred/frontend/src/middleware/02.feature.global.ts";
import manifest_45route_45rule from "/builds/raidboxes/fred/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45feature_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}